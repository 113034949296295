import axios, { AxiosError } from 'axios';
import { LocalStorage, STORAGE_KEY, isSuccessCode } from 'utils';
import { dispatch } from 'stores';
import { setLoading, showToast } from 'stores/common';
import { DELAY_TRANSITION, ROUTES } from 'constant';
import { APIResponse } from 'types';

const storage = new LocalStorage();

/** NOTE: APP GATEWAY AXIOS */
export const AxiosGW = axios.create({
  baseURL: process.env.REACT_APP_MOBILE_GATEWAY, // Replace with your API base URL
});

// Request interceptor e
AxiosGW.interceptors.request.use(
  (config) => {
    const { isDisableLoading } = config;
    const accessToken = storage.getStorageItem(STORAGE_KEY.ACCESS_TOKEN);

    if (!isDisableLoading) {
      dispatch(setLoading(true));
    }

    if (accessToken) {
      if (config.headers) config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError) => {
    throw error;
  },
);

// Response interceptor
AxiosGW.interceptors.response.use(
  (response) => {
    const { isDisableToast, method } = response.config;

    if (!isDisableToast && method !== 'get') {
      const code =
        response.data?.code == 4 ? response?.data?.description?.code : response.data?.code;
      dispatch(
        showToast({
          code: code,
          isError: !isSuccessCode(code),
        }),
      );
    }

    setTimeout(() => {
      dispatch(setLoading(false));
    }, DELAY_TRANSITION);

    return response;
  },
  (error: AxiosError) => {
    const { response, config } = error;
    const { isDisableToast } = config as any;

    if (response && response?.status !== 401) {
      const resData = response.data as APIResponse;
      const code = resData?.code == 4 ? resData?.description?.code : resData?.code;
      !isDisableToast &&
        dispatch(
          showToast({
            code: resData?.code == 4 ? resData.description?.message : code,
            isError: !isSuccessCode(code),
          }),
        );
    }

    setTimeout(() => {
      dispatch(setLoading(false));
    }, DELAY_TRANSITION);

    if (response?.status === 401) {
      storage.clearAllStorage();

      dispatch(
        showToast({
          code: '-1',
          isError: true,
        }),
      );

      setTimeout(() => {
        window.location.href = ROUTES.PAYMENT_HOME;
      }, 1000);
    }

    return response;
  },
);
