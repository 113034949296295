import React from 'react';
import styles from './styles.module.scss';
import SvgIcon from '../SVGIcon';
import { SecondaryButton } from 'components/button';
import { useDisclosure } from 'hooks';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMPTY } from 'constant';

export const INFOS = [
  {
    left: 'pr1',
    right: 'pr2',
  },
  {
    left: 'pr3',
    right: 'pr3',
  },
  {
    left: 'pr4',
    right: 'pr4',
  },
  {
    left: 'pr5',
    right: 'pr5',
  },
  {
    left: 'pr7',
    right: null,
  },
  {
    left: 'pr9',
    right: null,
  },
  {
    left: 'pr10',
    right: null,
  },
  {
    left: 'pr11',
    right: null,
  },
  {
    left: 'pr12',
    right: null,
  },
];

function ComparePlanDesktop() {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <p className="color-black font-lg">{t('paragraph.compare-title')}</p>
          <SecondaryButton
            text={isOpen ? t('button.hide-compare-plan') : t('button.compare-plan')}
            variant={isOpen ? 'outline' : 'high-light-blue'}
            style={{
              width: '100%',
              height: '56px',
            }}
            onClick={onToggle}
          />
        </div>
        <div
          className={styles.supportBackground}
          style={{
            backgroundImage: 'url("/images/support-background/bg-03.jpeg")',
          }}
        />
      </div>
      <div
        className={styles.compareTable}
        style={{
          display: isOpen ? 'block' : 'none',
        }}
      >
        <div className={styles.blockInfo}>
          <div className={`${styles.card} ${styles.blackCard}`}>
            <SvgIcon pathFill="var(--white-text-color)" src="/images/icon/plan-card.svg" />
            <p>Black Card</p>
          </div>
          <div className={styles.card}>
            <SvgIcon pathFill="var(--primary-text-color)" src="/images/icon/plan-card.svg" />
            <p>Classic/Basic</p>
          </div>
        </div>
        {INFOS.map((info, idx) => {
          return (
            <div key={idx} className={styles.block}>
              <div className={styles.left}>
                <p className="color-grey font-md font-light">
                  {info.left ? t(`paragraph.${info.left}`) : DEFAULT_EMPTY}
                </p>
              </div>
              <div className={styles.right}>
                <p className="color-grey font-md font-light">
                  {' '}
                  {info.right ? t(`paragraph.${info.right}`) : DEFAULT_EMPTY}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default React.memo(ComparePlanDesktop);
