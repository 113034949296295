import styles from './styles.module.scss';
import { Link, useParams } from 'react-router-dom';
import PriorityMembershipPlan from 'components/card/PriorityMembershipPlan';
import { ComparePlan, Faqs } from 'components';
import TabWrapper from 'components/shared/TabWrapper';
import { Element } from 'react-scroll';
import { useDirection, useDisclosure, useFormData } from 'hooks';
import { ApplyCodeInput } from 'components/input';
import GeneralService from 'services/general.service';
import {
  delayNavigate,
  isSuccessCode,
  isVN,
  LocalStorage,
  scrollToId,
  showData,
  STORAGE_KEY,
} from 'utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ClubType } from 'types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'antd-mobile';
import { ROUTES } from 'constant';
import GlobalService from 'services/global.service';
import { PaymentPlanType } from 'types/global.type';
import {
  BASIC_INFO_MAPPING,
  BASIC_MORE_INFO,
  BLACK_CARD_MORE_INFO,
  PLAN_NAME_MAPPING,
  SLOGAN_MAPPING,
  SORT_PLAN_PRIORITY,
} from 'constant/plan';
import { useDispatch } from 'stores';
import { setLoadingToast } from 'stores/common';
import { PAYMENT_PLAN_TYPE } from 'enum';
import ComparePlanDesktop from 'components/shared/ComparePlanDesktop';
import { sortBy } from 'lodash';

const Storage = new LocalStorage();

type PromotionForm = {
  promotionCode: string;
};

type TabType = {
  title: string;
  key: string;
}[];

const initPromotionForm: PromotionForm = {
  promotionCode: '',
};

export default function SelectMembershipContainer() {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();
  const { clubId } = useParams();
  const { goTo } = useDirection();
  const dispatch = useDispatch();
  const generalService = new GeneralService();
  const globalService = new GlobalService();
  const [clubDetail, setClubDetail] = useState<ClubType>();
  const [plans, setPlans] = useState<PaymentPlanType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  const [activeTab, setActiveTab] = useState<string | number>();
  const { formData, onChangeForm, KEY } = useFormData<PromotionForm>(initPromotionForm);
  const [isPromotion, setIsPromotion] = useState<boolean | null>(null);
  const [isPromotionComplete, setIsPromotionComplete] = useState<boolean>(false);
  const [promotionExtendError, setPromotionExtendError] = useState('');
  const promotionRef = useRef('');
  const planSaved = useRef<PaymentPlanType[]>([]);

  const getClubDetail = async () => {
    setIsLoading(true);
    const response = await generalService.getClubDetail({
      clubId,
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setClubDetail(data);
    }
    delayNavigate(() => setIsLoading(false));
  };

  const getPlansInClubs = async () => {
    setIsLoadingPlan(true);
    const response = await globalService.getPaymentPlans({
      clubId: String(clubId),
    });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setPlans(data);
      planSaved.current = data;
    }
    delayNavigate(() => setIsLoadingPlan(false));
  };

  const onCheckPromotion = async () => {
    if (!formData.promotionCode) return;
    dispatch(setLoadingToast(true));
    if (formData.promotionCode && formData.promotionCode != promotionRef.current) {
      const response = await globalService.getPaymentPlans({
        clubId: String(clubId),
        coupon: formData.promotionCode,
      });
      const { code, data } = response;
      if (isSuccessCode(code)) {
        setIsPromotion(true);
        setPromotionExtendError('');
        setPlans(data);
        Storage.setStorageItem(STORAGE_KEY.SAVED_COUPON, formData.promotionCode);
      } else {
        setIsPromotion(false);
        setPromotionExtendError(`error-message-code.${code}`);
      }
    } else {
      setIsPromotion(false);
      setPromotionExtendError(`validate-message.promo-code-invalid`);
    }
    setIsPromotionComplete(true);

    dispatch(setLoadingToast(false));
    promotionRef.current = formData.promotionCode;
  };

  const handleChangeActiveTab = (newTab: number | string) => {
    setActiveTab(newTab);
    scrollToId(String(newTab), 130);
  };

  const handleSelectPlan = (membershipId: number | string) => () => {
    goTo(
      ROUTES.SEND_OTP.replace(':clubId', String(clubId)).replace(
        ':membershipId',
        String(membershipId),
      ),
    )();
  };

  const promotionJoiningFee = (plan: PaymentPlanType) => {
    const promotions = plan.paymentPlanPromotions;
    if (!promotions) return null;
    const promotionHaveJoiningFee = promotions.find(
      (promotion) => Number(promotion.discountJoiningFee) > 0,
    );

    return {
      code: promotionHaveJoiningFee?.coupon?.code,
      discount: promotionHaveJoiningFee?.discountJoiningFee,
    };
  };

  const handleRemovePromotion = async () => {
    setIsPromotionComplete(false);
    setIsPromotion(null);
    onChangeForm(KEY.PROMOTION_CODE)('');
    setPromotionExtendError('');
    promotionRef.current = '';
    setPlans(planSaved.current);
  };

  const sortedPlan = useMemo(() => {
    const planMap = plans.map((plan) => ({ ...plan, priority: SORT_PLAN_PRIORITY[plan.type] }));

    return sortBy(planMap, 'priority');
  }, [plans]);

  const tabList = useMemo(() => {
    const result: TabType = [];
    sortedPlan.forEach((plan) => {
      result.push({
        title: `${plan.duration?.value} ${
          Number(plan?.duration?.value) > 1 ? t('title.months') : t('title.month')
        }`,
        key: `${plan.type}_${plan.id}`,
      });
    });
    setActiveTab(result[0]?.key);

    return result;
  }, [sortedPlan]);

  useEffect(() => {
    getClubDetail();
    getPlansInClubs();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.clubWrapper}>
        {isLoading ? (
          <Skeleton
            animated
            style={{
              height: 'var(--font-lg)',
            }}
          />
        ) : (
          <>
            <p className="font-lg color-black font-bold">
              {`TNG ${showData(isVN() ? clubDetail?.nameVi : clubDetail?.nameEn)}`}
            </p>
            <Link className="font-md color-blue font-light" to={ROUTES.PAYMENT_HOME}>
              {t('button.change')}
            </Link>
          </>
        )}
      </div>
      <div className={styles.info}>
        <p className="font-xxl color-black font-bold">{t('title.select-a-membership')}</p>
        <p
          className="font-sm color-black font-light"
          style={{
            marginTop: '8px',
            marginBottom: '11px',
          }}
        >
          {t('title.select-right-membership')}
        </p>
        {!isOpen ? (
          <div className={styles.promoCode} onClick={onToggle}>
            <img alt="" src="/images/icon/tag.svg" />
            <p className="font-lg color-blue font-bold">{t('paragraph.you-have-promo')}</p>
          </div>
        ) : (
          <div className={styles.promotionInput}>
            <ApplyCodeInput
              label={t('title.promo-code')}
              value={formData.promotionCode}
              isComplete={isPromotionComplete}
              successMessage={isPromotion ? 'validate-message.success-promotion-code-filter' : ''}
              apiErrorMessage={promotionExtendError}
              onChangeValue={onChangeForm(KEY.PROMOTION_CODE)}
              onApply={onCheckPromotion}
              onRemove={handleRemovePromotion}
            />
          </div>
        )}
      </div>
      <div className={styles.tabs}>
        <TabWrapper
          activeKey={activeTab}
          items={Object.values(tabList).map((tab) => {
            return {
              key: tab.key,
              title: tab.title,
            };
          })}
          onChangeTab={handleChangeActiveTab}
        />
      </div>

      <div className={styles.plan}>
        {isLoadingPlan ? (
          <>
            <div className={styles.item}>
              <PriorityMembershipPlan isDefault={true} isSkeleton basicInfos={[]} moreInfos={[]} />
            </div>
            <div className={styles.item}>
              <PriorityMembershipPlan isDefault={false} isSkeleton basicInfos={[]} moreInfos={[]} />
            </div>
            <div className={styles.item}>
              <PriorityMembershipPlan isDefault={false} isSkeleton basicInfos={[]} moreInfos={[]} />
            </div>
          </>
        ) : (
          sortedPlan.map((plan, idx) => {
            const numberOfPlans = sortedPlan.length;

            return (
              <div
                id={tabList[idx]?.key}
                key={plan.id}
                className={styles.item}
                style={{
                  width: `calc(100% / ${numberOfPlans} - (16px * ${
                    numberOfPlans - 1
                  }) / ${numberOfPlans})`,
                  maxWidth: '450px',
                }}
              >
                <PriorityMembershipPlan
                  isDefault={plan?.isDefault}
                  mainInfo={{
                    description: SLOGAN_MAPPING[plan.type]?.description,
                    duration: Number(plan.duration?.value),
                    planName: PLAN_NAME_MAPPING[plan.type],
                    pricePerMonth: plan.membershipPricePerMonth || 0,
                    price: plan.membershipFee || 0,
                    slogan: SLOGAN_MAPPING[plan.type]?.slogan,
                    unit: t('title.month'),
                    tagName: `${plan.duration?.value} ${
                      Number(plan?.duration?.value) > 1 ? t('title.months') : t('title.month')
                    }`,
                  }}
                  promotionCode={
                    plan?.type === PAYMENT_PLAN_TYPE.BASIC
                      ? promotionJoiningFee(plan)?.code
                      : undefined
                  }
                  totalPaymentFirst={
                    Number(plan.totalPrice) - Number(promotionJoiningFee(plan)?.discount)
                  }
                  nextPayment={plan.membershipPricePerMonth}
                  joiningFeeDiscount={promotionJoiningFee(plan)?.discount}
                  joiningFee={plan.joiningFee}
                  basicInfos={BASIC_INFO_MAPPING[plan.type]}
                  moreInfos={plan.isBlackCard ? BLACK_CARD_MORE_INFO : BASIC_MORE_INFO}
                  onSelect={handleSelectPlan(String(plan.id))}
                />
              </div>
            );
          })
        )}
      </div>
      <div>
        <Element name="firstInsideContainer">
          <div className={styles.comparePlan}>
            <ComparePlan />
          </div>
          <div className={styles.comparePlanDesktop}>
            <ComparePlanDesktop />
          </div>
        </Element>
      </div>

      <div className={styles.faqs}>
        <Faqs />
      </div>
    </div>
  );
}
