import { PaymentContainer } from 'containers';
import { MainLayout } from 'layouts';

type Props = {};

export default function PaymentPage({}: Props) {
  return (
    <MainLayout isStepHeader step={2} isHideFooter haveBottomSpace>
      <PaymentContainer />
    </MainLayout>
  );
}
