import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from 'locales/en.json';
import vietnamese from 'locales/vi.json';
import { LANGUAGE } from 'enum';
import { LocalStorage, STORAGE_KEY } from './localStorage';
import _ from 'lodash';

const Storage = new LocalStorage();

if (_.isEmpty(Storage.getStorageItem(STORAGE_KEY.LANGUAGE))) {
  Storage.setStorageItem(STORAGE_KEY.LANGUAGE, LANGUAGE.VN);
}

const resources = {
  en: {
    translation: english,
  },
  vi: {
    translation: vietnamese,
  },
  'vi-VN': {
    translation: vietnamese,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: LANGUAGE.VN,
  resources,

  lng: Storage.getStorageItem(STORAGE_KEY.LANGUAGE) || LANGUAGE.VN,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
