import React from 'react';

import { Divider, Skeleton } from 'antd-mobile';

import styles from './styles.module.scss';
import { SecondaryButton } from 'components/button';
import { useTranslation } from 'react-i18next';
import { showData } from 'utils';

type ClubInfo = {
  clubName?: string;
  clubAddress?: string;
  clubDistance?: number;
  isSkeleton?: boolean;
  clubDetailUrl?: string;
  onReviewPlanUrl?: string;
};

const ClubCard = ({
  clubName,
  clubDistance,
  clubAddress,
  clubDetailUrl,
  onReviewPlanUrl,
  isSkeleton = false,
}: ClubInfo) => {
  const { t } = useTranslation();

  return (
    <div className={styles.cardWrapper}>
      <div>
        <div className={styles.cardClubTitle}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: 'var(--font-lg)',
              }}
            />
          ) : (
            <h2 className={styles.cardClubName}>TNG {showData(clubName)}</h2>
          )}
          {clubDistance && !isSkeleton && (
            <p className={styles.cardClubDistant}>{`${showData(clubDistance)} km`}</p>
          )}
        </div>
        <div className={styles.cardClubLocate}>
          {isSkeleton ? (
            <Skeleton
              animated
              style={{
                width: '100%',
                height: 'var(--font-sm)',
              }}
            />
          ) : (
            <>
              <div className={styles.cardClubIcon}>
                <img src="/images/icon/location.svg" alt="location" />
              </div>
              <p className={styles.cardClubAddress}>{showData(clubAddress)}</p>
            </>
          )}
        </div>
      </div>
      <div>
        <Divider className={styles.cardClubLine} />
        <div className={styles.cardClubAction}>
          {!isSkeleton && (
            <a href={clubDetailUrl} className={styles.cardClubDetailButton}>
              {t('button.club-detail')}
            </a>
          )}
          <a href={onReviewPlanUrl}>
            <SecondaryButton
              text={t('button.review-plans')}
              variant="high-light-blue"
              disabled={isSkeleton}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ClubCard;
