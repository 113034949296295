export enum CARD_TYPE {
  DOMESTIC = '01',
  INTERNATIONAL = '02',
}

export enum PAYMENT_METHOD {
  QR = 1,
  CARD = 2,
  CASH = 3,
  CARD_VNP = 4,
}
