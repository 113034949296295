import styles from './styles.module.scss';
import InputPhoneNumber from 'components/input/InputPhoneNumber';
import { SecondaryButton } from 'components';
import { useDirection, useFormData, useValidation } from 'hooks';
import { delayNavigate, isSuccessCode, JOI } from 'utils';
import { AuthService } from 'services';
import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';

type FormData = {
  phoneNumber: string;
};

const initForm: FormData = {
  phoneNumber: '',
};

export default function SendOtpContainer() {
  const { t } = useTranslation();
  const { goTo } = useDirection();
  const authService = new AuthService();
  const { formData, KEY, onChangeForm } = useFormData<FormData>(initForm);
  const { errorMessage, onValidate } = useValidation<FormData>({
    phoneNumber: JOI.PHONE_REQUIRE,
  });
  const [isLoading, setIsLoading] = useState(false);

  const sendOTPCode = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isError = onValidate(formData);
    if (!isError) {
      setIsLoading(true);
      const response = await authService.sendOTPLogin(formData);
      const { code } = response;
      if (isSuccessCode(code)) {
        delayNavigate(() => {
          goTo('verify', {
            state: {
              phoneNumber: formData.phoneNumber,
              replace: true,
            },
          })();
        });
      }
      delayNavigate(() => setIsLoading(false));
    }
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={sendOTPCode}>
        <p className="font-xxl color-black font-bold">{t('title.what-is-your-phone')}</p>
        <div className={styles.input}>
          <InputPhoneNumber
            value={formData.phoneNumber}
            onChange={onChangeForm(KEY.PHONE_NUMBER)}
            errorMessage={errorMessage.phoneNumber}
          />
        </div>
        <div className={styles.controller}>
          <SecondaryButton
            text={t('button.continue')}
            variant="high-light-blue"
            type="submit"
            style={{
              width: '100%',
            }}
            loading={isLoading}
          />
        </div>
      </form>
    </div>
  );
}
