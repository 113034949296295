import { useMemo } from 'react';
import { useDisclosure } from 'hooks';
import styles from './styles.module.scss';
import { CityType } from 'types/general.type';
import { isNull } from 'lodash';
import { SvgIcon } from 'components/shared';
import { useTranslation } from 'react-i18next';

interface Props {
  options: CityType[];
  selectedId: number | null;
  onChangeCity: (cityId: number | null) => void;
}

const CitySelector = ({ options, selectedId, onChangeCity }: Props) => {
  const { isOpen, onToggle } = useDisclosure();
  const { t } = useTranslation();

  const selectedCity = useMemo(() => {
    if (!isNull(selectedId)) {
      const city = options.find((city) => city.id === selectedId);

      return city;
    }

    return null;
  }, [selectedId, options]);

  const onChange = (cityId: number) => () => {
    onChangeCity(cityId);
    onToggle();
  };

  const handleClearSelect = () => {
    onChangeCity(null);
  };

  return (
    <div className={styles.searchClub}>
      <div className={styles.searchWrapper}>
        <div className={`${styles.search} ${isOpen ? styles.active : ''}`} onClick={onToggle}>
          <div className={styles.searchClubIcon}>
            <img src="/images/icon/search-normal.svg" alt="search-normal" />
          </div>
          <div className={styles.searchClubInput}>
            <p className="font-sm color-grey font-light">
              {isNull(selectedId) ? t('placeholder.select-your-city') : selectedCity?.cityName}
            </p>
          </div>
        </div>
        {!isNull(selectedId) && (
          <div className={styles.closeIcon} onClick={handleClearSelect}>
            <SvgIcon src="/images/icon/close.svg" />
          </div>
        )}
      </div>
      <div className={`${styles.searchClubResult} ${isOpen ? styles.show : ''}`}>
        <ul className={styles.searchClubList}>
          {options.map((city) => (
            <li key={city.id} className={styles.searchClubItem} onClick={onChange(Number(city.id))}>
              {city.cityName}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CitySelector;
