import { useEffect, useMemo, useState } from 'react';
import { ClubCard } from 'components';
import CitySelector from 'components/input/CitySelector';
import styles from './styles.module.scss';
import { ROUTES } from 'constant';
import { useTranslation } from 'react-i18next';
import { CityType } from 'types/general.type';
import GeneralService from 'services/general.service';
import {
  delayNavigate,
  getDistanceFromLatLonInKm,
  getLocation,
  isSuccessCode,
  isVN,
  joinAddress,
} from 'utils';
import { ClubType } from 'types';
import _, { isEmpty, isNull } from 'lodash';
import { dispatch, useSelector } from 'stores';
import { commonSelector, storeLocation } from 'stores/common';
import { useSearchParams } from 'react-router-dom';

const ClubNearYouContainer = () => {
  const [searchParams] = useSearchParams();
  const initCity = searchParams.get('cityId');
  const generalService = new GeneralService();
  const { t } = useTranslation();
  const [listCity, setListCity] = useState<CityType[]>([]);
  const [selectedCityId, setSelectedCityId] = useState<null | number>(
    initCity ? Number(initCity) : null,
  );
  const [clubMapping, setClubMapping] = useState<ClubType[]>([]);
  const [isLoadingDistance, setIsLoadingDistance] = useState(false);
  const [isLoadingClub, setIsLoadingClub] = useState(false);
  const [distanceMapping, setDistanceMapping] = useState<{
    [key: string]: { id: string; distance: number };
  }>({});
  const { location } = useSelector(commonSelector);

  const getListCityHaveClub = async () => {
    const response = await generalService.getCityHaveClub();
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setListCity(data);
    }
  };

  const getListClub = async () => {
    setIsLoadingClub(true);
    const response = await generalService.getClubInCity({ cityId: undefined });
    const { data, code } = response;

    if (isSuccessCode(code)) {
      setClubMapping(data);
    }
    delayNavigate(() => setIsLoadingClub(false));
  };

  const handleChangeCity = (cityId: number | null) => {
    setSelectedCityId(cityId);
  };

  const filterClubByCityId = (cityId: number | null) => {
    if (!isNull(cityId)) {
      return sortClub.filter((club) => club.cityId === cityId);
    } else {
      return sortClub;
    }
  };

  const success = (position: GeolocationPosition) => {
    dispatch(
      storeLocation({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      }),
    );

    setIsLoadingDistance(false);
  };

  const sortClub = useMemo(() => {
    if (_.isEmpty(distanceMapping)) return clubMapping;
    else {
      const sortedClubs: ClubType[] = [];
      const clubKeyBy = _.keyBy(clubMapping, 'id');
      const mappings = _.sortBy(_.valuesIn(distanceMapping), 'distance');
      mappings.forEach((club) => {
        sortedClubs.push(clubKeyBy[club.id]);
      });

      return sortedClubs;
    }
  }, [distanceMapping, clubMapping]);

  useEffect(() => {
    const result: { id: string | number | undefined; distance: number }[] = [];
    clubMapping.forEach((clubs) => {
      if (clubs.latitude && clubs.longitude && location?.lat && location?.long) {
        const data = getDistanceFromLatLonInKm(
          location.lat,
          location.long,
          clubs.latitude,
          clubs.longitude,
        );
        result.push({ id: clubs.id, distance: Math.ceil(data * 10) / 10 });
      }
    });
    setDistanceMapping(_.keyBy(result, 'id') as any);
  }, [clubMapping, location]);

  useEffect(() => {
    if (isEmpty(location)) {
      getLocation(
        () => {
          setIsLoadingDistance(true);
        },
        success,
        (positionError: GeolocationPositionError) => {
          setIsLoadingDistance(false);
          console.log('Error: ', positionError);
        },
        () => {
          setIsLoadingDistance(false);
        },
      );
    }
  }, []);

  useEffect(() => {
    getListCityHaveClub();
    getListClub();
  }, []);

  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.searchWrapper}>
        <CitySelector
          options={listCity}
          selectedId={selectedCityId}
          onChangeCity={handleChangeCity}
        />
      </div>
      <p className={styles.clubNearYouTitle}>{t('title.find-club-near-you')}</p>
      <div className={styles.clubCardList}>
        {isLoadingDistance || isLoadingClub
          ? [1, 2, 3, 4].map((_item, idx) => {
              return (
                <div key={idx} className={styles.clubCard}>
                  <ClubCard isSkeleton />
                </div>
              );
            })
          : filterClubByCityId(selectedCityId).map((club) => (
              <div key={club.id} className={styles.clubCard}>
                <ClubCard
                  clubName={isVN() ? club.nameVi : club.nameEn}
                  clubAddress={joinAddress(club)}
                  clubDistance={distanceMapping[String(club.id)]?.distance}
                  clubDetailUrl={club?.informationUrl}
                  onReviewPlanUrl={ROUTES.SELECT_MEMBERSHIP.replace(':clubId', String(club.id))}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default ClubNearYouContainer;
