import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useLocation, useRoutes } from 'react-router';
import Router from './routes/Router';
import { ToastLoadingOverlay, ToastOverlay } from 'components';
import { useSelector } from 'react-redux';
import { commonSelector } from 'stores/common';
import { resetScroll } from 'utils';
import 'moment/locale/en-au';
import 'moment/locale/vi';

function App() {
  const routing = useRoutes(Router);
  const { isShowToast, toastContent, isShowLoadingToast } = useSelector(commonSelector);
  const { pathname } = useLocation();

  useEffect(() => {
    resetScroll();
  }, [pathname]);

  return (
    <>
      <ToastLoadingOverlay isOpen={isShowLoadingToast} />
      <ToastOverlay isOpen={isShowToast} isError={toastContent.isError} code={toastContent.code} />
      <AnimatePresence exitBeforeEnter>{routing}</AnimatePresence>
    </>
  );
}

export default App;
