import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { Divider } from 'antd-mobile';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();

  const handleScrollToTop = () => {
    document.body?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.backToTop} onClick={handleScrollToTop}>
        <ReactSVG src="/images/icon/arrow-tail-up.svg" />
        <p className="font-sm">{t('button.back-to-top')}</p>
      </div>
      <div className={styles.listItem}>
        <Link to={'https://thenewgym.vn/he-thong-phong-tap/'}>{t('title.find-a-club')}</Link>
        <Link to={'https://thenewgym.vn/category/tin-tuc/'}>{t('title.blog')}</Link>
        <Link to={'https://thenewgym.vn/ve-the-new-gym/'}>{t('title.about-tng')}</Link>
        {/* <Link to={''}>{t('title.careers')}</Link> */}
        {/* <Link to={''}>{t('title.memberships')}</Link> */}
        <Link to={'https://thenewgym.vn/lien-he/'}>{t('title.customer-serivce')}</Link>
        <Link to={'https://thenewgym.vn/dieu-khoan-dieu-kien/'}>
          {t('title.term-and-conditions')}
        </Link>
      </div>
      <Divider
        style={{
          margin: '24px 0',
        }}
      />
      <div className={styles.social}>
        <Link to={'https://www.facebook.com/thenewgymvietnam'} target="_blank">
          <img alt="" src="/images/icon/facebook.svg" />
        </Link>
        <Link to={'https://www.instagram.com/thenewgymvietnam'} target="_blank">
          <img alt="" src="/images/icon/instagram.svg" />
        </Link>
        <Link to={'https://www.youtube.com/@thenewgymvn'} target="_blank">
          <img alt="" src="/images/icon/youtube.svg" />
        </Link>
        <Link to={'https://www.tiktok.com/@thenewgym'} target="_blank">
          <img alt="" src="/images/icon/tiktok.svg" />
        </Link>
      </div>
    </div>
  );
}
