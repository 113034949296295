export const DEFAULT_EMPTY = '-';

export const DELAY_TRANSITION = 150;

export const DATE_FORMAT = 'DD/MM/YYYY';

export const TIME_FORMAT = 'HH:mm';

export const DATETIME_FORMAT = 'HH:mm - DD/MM/YYYY';

export const APP_NAME = 'The New Gym';

export const HEADER_HEIGHT = 54;

export const THEME_COLOR = {
  MAIN: '#009fc2',
  SUB: '#e7f5f7',
};

export const UNIT_PRICE = 'Đ';
