import { Swiper } from 'antd-mobile';
import styles from './styles.module.scss';
import React from 'react';

type Props = {
  items?: React.ReactNode[];
  emptyElement?: React.ReactNode;
};

function CardSwiper({ items = [], emptyElement }: Props) {
  return items.length > 0 ? (
    <Swiper
      className={styles.listItem}
      style={{
        '--track-padding': '0 0 32px 0',
      }}
      indicator={items.length === 1 ? false : undefined}
    >
      {items?.map((item, idx) => {
        return (
          <Swiper.Item key={idx}>
            <div className={styles.inner}>{item}</div>
          </Swiper.Item>
        );
      })}
    </Swiper>
  ) : (
    <div
      style={{
        padding: '0 0 16px',
      }}
    >
      {emptyElement}
    </div>
  );
}

export default React.memo(CardSwiper);
