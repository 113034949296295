/* eslint-disable @typescript-eslint/no-unused-vars */
import { Switch } from 'antd-mobile';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  activeText?: string;
  defaultText?: string;
  isChecked?: boolean;
  onChange?: (value: boolean) => void;
};

function CustomSwitch({ defaultText = '', activeText = '', isChecked, onChange }: Props) {
  return (
    <div className={styles.wrapper}>
      <Switch
        checked={isChecked}
        className={styles.switch}
        uncheckedText={defaultText}
        checkedText={activeText}
        onChange={onChange}
        style={{
          '--height': '33px',
          '--width': '67px',
          '--checked-color': 'var(--primary-color)',
        }}
      />
    </div>
  );
}

export default React.memo(CustomSwitch);
