import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  isShowToast: boolean;
  isShowLoadingToast: boolean;
  location?: {
    lat: number;
    long: number;
  };
  toastContent: {
    isError?: boolean;
    code?: number;
    message?: string;
    description?: {
      status?: number;
      message?: string;
    };
  };
}

const initialState: State = {
  isLoading: false,
  isShowToast: false,
  isShowLoadingToast: false,
  location: undefined,
  toastContent: {},
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state: State, action) => {
      if (state.isLoading != action.payload) {
        state.isLoading = action.payload;
      }
    },
    setLoadingToast: (state: State, action) => {
      state.isShowLoadingToast = action.payload;
    },
    showToast: (state: State, action) => {
      state.isShowToast = true;
      state.toastContent = action.payload;
    },
    clearToast: (state: State) => {
      state.isShowToast = false;
    },
    storeLocation: (state: State, action) => {
      state.location = {
        lat: action.payload.lat,
        long: action.payload.long,
      };
    },
  },
});

export const { setLoading, showToast, clearToast, setLoadingToast, storeLocation } =
  commonSlice.actions;
export default commonSlice.reducer;
