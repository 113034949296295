import { VerifyOtpContainer } from 'containers';
import { MainLayout } from 'layouts';

type Props = {};

export default function VerifyOtpPage({}: Props) {
  return (
    <MainLayout isStepHeader step={1} isHideFooter>
      <VerifyOtpContainer />
    </MainLayout>
  );
}
