import React from 'react';
import Header from './Header';
import styles from './styles.module.scss';
import Footer from './Footer';
import HeaderStep from './HeaderStep';
import { BottomSpace } from 'components';
import { useTranslation } from 'react-i18next';

type Props = {
  children?: React.ReactNode;
  isStepHeader?: boolean;
  isHideFooter?: boolean;
  haveBottomSpace?: boolean;
  step?: number;
};

function MainLayout({
  isStepHeader,
  step,
  children,
  isHideFooter = false,
  haveBottomSpace = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapperLayout}>
      <p className={styles.slogan}>{t('paragraph.pr45')}</p>
      {isStepHeader ? <HeaderStep step={step} /> : <Header />}
      <div id="main-body" className={styles.body}>
        {children}
        {haveBottomSpace && <BottomSpace />}
      </div>
      {!isHideFooter && <Footer />}
    </div>
  );
}

export default React.memo(MainLayout);
