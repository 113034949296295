import { ContractDetailContainer } from 'containers';
import { MainLayout } from 'layouts';

type Props = {};

export default function ContractDetailPage({}: Props) {
  return (
    <MainLayout isStepHeader step={4} isHideFooter haveBottomSpace>
      <ContractDetailContainer />
    </MainLayout>
  );
}
