import { SelectMembershipContainer } from 'containers';
import { MainLayout } from 'layouts';
import React from 'react';

type Props = {};

export default function SelectMembershipPage({}: Props) {
  return (
    <MainLayout isStepHeader step={0}>
      <SelectMembershipContainer />
    </MainLayout>
  );
}
