import React from 'react';
import { ClubNearYouContainer } from 'containers';
import { MainLayout } from 'layouts';

type Props = {};

export default function PaymentHomePage({}: Props) {
  return (
    <MainLayout>
      <ClubNearYouContainer />
    </MainLayout>
  );
}
