import { RouteObject } from 'react-router-dom';
import { ROUTES } from 'constant';
import {
  PaymentHomePage,
  PaymentPage,
  SelectMembershipPage,
  SendOtpPage,
  ContractDetailPage,
  VerifyOtpPage,
} from 'pages';

const Router: RouteObject[] = [
  /** HOME */
  {
    path: '/',
    children: [
      { path: ROUTES.PAYMENT_HOME, element: <PaymentHomePage /> },
      { path: ROUTES.SELECT_MEMBERSHIP, element: <SelectMembershipPage /> },
      { path: ROUTES.SEND_OTP, element: <SendOtpPage /> },
      { path: ROUTES.VERIFY_OTP, element: <VerifyOtpPage /> },
      { path: ROUTES.PAYMENT, element: <PaymentPage /> },
      { path: ROUTES.CONTRACT_DETAIL, element: <ContractDetailPage /> },
    ],
  },
];

export default Router;
