/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Button, Input } from 'antd-mobile';
import { showData } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  errorMessage?: string;
  successMessage?: string;
  apiErrorMessage?: string;
  isApplySuccess?: boolean;
  isComplete?: boolean;
  onApply?: () => void;
  onChangeValue?: (newValue: string) => void;
  onRemove?: () => void;
};

function ApplyCodeInput({
  value = '',
  label = '',
  type = 'text',
  errorMessage = '',
  successMessage = '',
  apiErrorMessage = '',
  isComplete = false,
  onApply,
  onChangeValue,
  onRemove,
}: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={styles.wrapper}
        style={{
          borderColor: successMessage
            ? 'var(--sub-high-light-color)'
            : errorMessage || apiErrorMessage
            ? 'var(--high-light-color)'
            : 'transparent',
        }}
      >
        <p
          className="color-grey font-us font-bold"
          style={{
            textAlign: 'left',
            color: successMessage
              ? 'var(--sub-high-light-color)'
              : errorMessage || apiErrorMessage
              ? 'var(--high-light-color)'
              : 'var(--sub-text-color)',
          }}
        >
          {showData(label)}
        </p>
        <Input
          placeholder={t('placeholder.enter-code')}
          type={type}
          value={value}
          onChange={onChangeValue}
          readOnly={isComplete}
        />
        {isComplete ? (
          <Button className={styles.removeButton} onClick={onRemove}>
            {t('button.remove')}
          </Button>
        ) : (
          <Button onClick={onApply}>{t('button.apply')}</Button>
        )}
      </div>
      {successMessage ? (
        <p className={styles.successMessage}>{t(successMessage)}</p>
      ) : errorMessage ? (
        <p className={styles.errorMessage}>{t(errorMessage)}</p>
      ) : null}
      {apiErrorMessage && <p className={styles.errorMessage}>{t(apiErrorMessage)}</p>}
    </div>
  );
}

export default React.memo(ApplyCodeInput);
